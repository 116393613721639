<template>
  <div>
    <el-row :gutter="20" class="mb-5" type="flex" justify="space-between">
      <el-col :span="8">
        <div class="block">
          <el-date-picker v-model="filters.dates" type="daterange" align="right" start-placeholder="Start Date"
            end-placeholder="End Date" format="yyyy-MM-dd" value-format="yyyy-MM-dd" clearable @change="initialize()">
          </el-date-picker>
        </div>
      </el-col>
      <el-col :span="6">
        <el-input placeholder="Search by code or name" prefix-icon="el-icon-search"
          v-model="filters.searchString" /></el-col>
      <el-col :span="3.5"><el-button type="success" icon="el-icon-circle-check"
          @click="onComplete">Complete</el-button></el-col>
      <el-col :span="3.5"><el-button type="danger" icon="el-icon-circle-close"
          @click="dialogs.rejection = true">Reject</el-button></el-col>
      <el-col :span="4">
        <p class="mt-2">{{ itemsShowing }}</p>
      </el-col>
    </el-row>
    <el-table ref="elTable" :data="filteredData" :row-key="getRowKey" @selection-change="handleSelectionChange"
      style="width: 100%" border size="small" :cell-style="{ padding: '0', height: '20px' }"
      :header-cell-style="{ background: '#f1f1f5', color: 'black', textAlign: 'center' }">
      <el-table-column type="selection" width="35" :reserve-selection="true" />
      <el-table-column width="100">
        <template slot-scope="scope">
          <el-image :src="getImage(scope.row.igoalStock.imageLink)" style="width: 80px; height: 80px"
            class="my-2 rounded-lg" alt="Loading" lazy fit="fit" @click="previewURL(scope.row.igoalStock.imageLink)" />
        </template>
      </el-table-column>
      <el-table-column label="Date" width="100" align="center">
        <template slot-scope="scope">
          {{ formatDate(scope.row.created_date) }}
        </template>
      </el-table-column>
      <el-table-column property="igoalStock.itemCode" label="Code" align="center" :filters="filters.itemCodes"
        :filter-method="filterHandler" />
      <el-table-column property="igoalStock.itemName" label="Name" :filters="filters.itemNames"
        :filter-method="filterHandler" />
      <el-table-column property="igoalStock.itemGroup" label="Group" align="center" :filters="filters.itemGroups"
        :filter-method="filterHandler" />
      <el-table-column property="branch.code" label="Branch" align="center" :filters="filters.branches"
        :filter-method="filterHandler" />
      <el-table-column property="created_by.name" label="User" align="center" :filters="filters.users"
        :filter-method="filterHandler" />
      <el-table-column property="igoalStock.stock" label="Stock" />
      <el-table-column property="igoalStock.costPrice" label="Cost Price" />
      <el-table-column property="reqQty" label="R.Qty" />
      <el-table-column property="" label="Total Cost">
        <template slot-scope="scope">
          {{ Number(scope.row.igoalStock.costPrice) * Number(scope.row.reqQty) }}
        </template>
      </el-table-column>
      <el-table-column property="" label="A.Qty" align="center">
        <template slot-scope="scope">
          <el-input v-model.number="scope.row.givenQty" size="mini" :min="1" max="scope.row.igoalStock.stock"
            :disabled="!isRowSelected(scope.row)" />
        </template>
      </el-table-column>
    </el-table>
    <reject-reason-modal :visible.sync="dialogs.rejection" :rejectReason.sync="selected.rejectionReason"
      @save="onReject" />
  </div>
</template>

<script>
import RejectReasonModal from "../../components/RejectReasonModal.vue";
import { getImage } from "../../common/utils";
import { imagePreviewHelper } from "../../config/constants";
import { getAllRequests, completeRequests, rejectRequests } from "./lib";
import { DateService } from "../../common/DateService";

export default {
  name: "IGoalStock",
  components: {
    RejectReasonModal,
  },
  computed: {
    filteredData() {
      let searchString = this.filters.searchString;
      const filteredRequests = this.requests.filter(function (item) {
        return (
          item.igoalStock.itemName.toUpperCase().indexOf(searchString.toUpperCase()) >= 0 ||
          item.igoalStock.itemCode.toUpperCase().indexOf(searchString.toUpperCase()) >= 0
        );
      });

      this.$nextTick(() => {
        this.triggerScrollEvent();
      });

      return filteredRequests
    },
    itemsShowing() {
      if (!this.isMounted) return;
      const filteredItemsLength = this.$refs.elTable.tableData.length;
      const totalItemsLength = this.requests.length;
      return `Showing ${filteredItemsLength} / ${totalItemsLength} Items`;
    },
  },
  data() {
    return {
      requests: [],
      isMounted: false,
      selected: {
        items: [],
        rejectionReason: "",
      },
      filters: {
        itemCodes: [],
        itemGroups: [],
        itemNames: [],
        branches: [],
        users: [],
        searchString: "",
        dates: [],
      },
      dialogs: {
        rejection: "",
      },
    };
  },
  methods: {
    getImage: getImage,
    formatDate: DateService.formatDate,
    previewURL(imageLink) {
      this.$viewerApi(imagePreviewHelper(imageLink));
    },
    triggerScrollEvent() {
      const scrollEvent = new Event('scroll');
      window.dispatchEvent(scrollEvent);
    },
    filterHandler(value, row, column) {
      return this.resolve(column["property"], row) === value;
    },
    resolve(path, obj) {
      return path.split(".").reduce(function (prev, curr) {
        return prev ? prev[curr] : null;
      }, obj || self);
    },
    getRowKey(row) {
      return row.id;
    },
    handleSelectionChange(item) {
      this.selected.items = item;
    },
    async initialize() {
      let _dateQueryParam = {};
      if (this.filters.dates) {
        _dateQueryParam = {
          startDate: this.filters.dates[0],
          endDate: this.filters.dates[1],
        };
      }
      this.requests = await getAllRequests(_dateQueryParam);
      this.$refs.elTable.clearSelection();
      this.setFilters();
    },
    isRowSelected(row) {
      return this.selected.items.find((item) => item.id === row.id);
    },
    setFilters() {
      this.filters.itemCodes = [...new Set(this.requests.map((item) => item.igoalStock.itemCode))]
        .map((item) => ({ text: item, value: item }))
        .sort((a, b) => a.text.localeCompare(b.text));
      this.filters.itemGroups = [...new Set(this.requests.map((item) => item.igoalStock.itemGroup))]
        .map((item) => ({ text: item, value: item }))
        .sort((a, b) => a.text.localeCompare(b.text));
      this.filters.itemNames = [...new Set(this.requests.map((item) => item.igoalStock.itemName))]
        .map((item) => ({ text: item, value: item }))
        .sort((a, b) => a.text.localeCompare(b.text));
      this.filters.branches = [...new Set(this.requests.map((item) => item.branch.code))]
        .map((item) => ({ text: item, value: item }))
        .sort((a, b) => a.text.localeCompare(b.text));
      this.filters.users = [...new Set(this.requests.map((item) => item.created_by.name))]
        .map((item) => ({ text: item, value: item }))
        .sort((a, b) => a.text.localeCompare(b.text));
    },
    async onComplete() {
      if (this.selected.items.length == 0) {
        this.$notify({
          title: "Please select at least one item",
          type: "error",
        });
        return;
      }

      const _body = {
        data: [],
      };

      for (const { id, givenQty, igoalStock } of this.selected.items) {
        if (givenQty <= 0 || isNaN(givenQty)) {
          this.$notify({
            title: `Please enter a valid allocated quantity : ${igoalStock.itemCode}`,
            type: "error",
          });
          return;
        }

        // if (igoalStock.stock < givenQty) {
        //   this.$notify({
        //     title: `Allocated quantity higher than stock : ${igoalStock.itemCode}`,
        //     type: "error",
        //   });
        //   return;
        // }

        _body.data.push({ id: id, qty: givenQty });
      }

      await this.$store.dispatch("setLoading", true);

      try {
        const res = await completeRequests(_body);
        if (res?.status == 200) {
          this.$notify({
            title: "Selected requests completed successfully.",
            type: "success",
          });
          await this.initialize();
        }
        await this.$store.dispatch("setLoading", false);
      } catch (error) {
        await this.$store.dispatch("setLoading", false);
        this.$notify({
          title: error.response.data.message,
          text: error.response.data.message,
          type: "error",
        });
      }
    },
    async onReject() {
      if (this.selected.items.length == 0) {
        this.$notify({
          title: "Please select at least one item",
          type: "error",
        });
        return;
      }
      const _body = {
        data: this.selected.items.map((item) => ({ id: item.id })),
        remark: this.selected.rejectionReason,
      };

      try {
        const res = await rejectRequests(_body);
        if (res?.status == 200) {
          this.$notify({
            title: "Selected requests rejected successfully.",
            type: "success",
          });
          await this.initialize();
        }
        this.dialogs.rejection = false;

        await this.$store.dispatch("setLoading", false);
      } catch (error) {
        await this.$store.dispatch("setLoading", false);
        this.$notify({
          title: error.response.data.message,
          text: error.response.data.message,
          type: "error",
        });
      }
    },
  },
  async mounted() {
    await this.$store.dispatch("setLoading", true);
    await this.initialize();
    this.isMounted = true;
    await this.$store.dispatch("setLoading", false);
  },
};
</script>

<style></style>
