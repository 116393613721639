import axios from "axios";

export async function getAvailableStock() {
  try {
    const response = await axios.get(`igoal-sale/availableStock`);
    return response.data;
  } catch (error) {
    console.log(error);
  }
}

export async function addNewStock(fileLink) {
  try {
    return await axios.post("igoal-sale/add", { fileLink });
  } catch (error) {
    console.log(error);
  }
}

export async function updateImage(id, imageLink) {
  try {
    return await axios.patch(`igoal-sale/updateStockById/${id}`, { imageLink });
  } catch (error) {
    console.log(error);
  }
}

export async function getAllRequests({ startDate, endDate }) {
  let queryStr = "";
  if (startDate) {
    queryStr = `?startDate=${startDate}&endDate=${endDate}`;
  }
  try {
    const response = await axios.get(`igoal-sale/allPendingRequests${queryStr}`);
    return response.data;
  } catch (error) {
    console.log(error);
  }
}

export async function completeRequests(body) {
  try {
    const response = await axios.patch(`igoal-sale/completeRequests`, body);
    return response;
  } catch (error) {
    console.log(error);
  }
}

export async function rejectRequests(body) {
  try {
    const response = await axios.patch(`igoal-sale/rejectRequests`, body);
    return response;
  } catch (error) {
    console.log(error);
  }
}

export async function requestsHistory({ startDate, endDate }) {
  let queryStr = "";
  if (startDate) {
    queryStr = `?startDate=${startDate}&endDate=${endDate}`;
  }
  try {
    const response = await axios.get(`igoal-sale/requestsHistory${queryStr}`);
    return response.data;
  } catch (error) {
    console.log(error);
  }
}

export async function exportIgoalStockWithImage() {
  try {
    try {
      const response = await axios.post(`excel-generation/igoalStockWithImage`, {}, { responseType: "arraybuffer" });
      forceFileDownload(response, "IGoal Stock");
    } catch (error) {
      console.log(JSON.stringify(error));
    }
  } catch (error) {
    console.log(error);
  }
}

export function forceFileDownload(response, fileName) {
  var today = new Date();
  var dd = today.getDate();

  var mm = today.getMonth() + 1;
  var yyyy = today.getFullYear();

  if (dd < 10) {
    dd = "0" + dd;
  }

  if (mm < 10) {
    mm = "0" + mm;
  }
  today = mm + "-" + dd + "-" + yyyy;

  const url = URL.createObjectURL(
    new Blob([response.data], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    })
  );
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", `${fileName} - ${today}.xlsx`);
  document.body.appendChild(link);
  link.click();
}
