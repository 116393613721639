import { getImage } from "../common/utils";

export function imagePreviewHelper(imageLink) {
  return {
    options: {
      inline: false,
      button: true,
      navbar: false,
      title: false,
      toolbar: false,
      tooltip: false,
      movable: true,
      zoomable: true,
      rotatable: true,
      scalable: true,
      transition: true,
      fullscreen: false,
      keyboard: false,
    },
    images: [getImage(imageLink)],
  };
}
